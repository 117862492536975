import React from "react";
import Slider from "react-slick";

import completejob1 from "../../assets/completejob1.png";
import completejob2 from "../../assets/completejob2.png";
import completejob3 from "../../assets/completejob3.png";
import completejob4 from "../../assets/completejob4.png";
import completejob5 from "../../assets/completejob5.png";
import completejob6 from "../../assets/completejob6.png";
import completejob7 from "../../assets/completejob7.png";
import completejob8 from "../../assets/completejob8.png";

const CompletedJobs = () => {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 2500,
          loop: true,
        },
      },
    ],
  };
  const jobsData = [
    {
      src: completejob1,
      title: "Roofing",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
    {
      src: completejob2,
      title: "Cleaning",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
    {
      src: completejob3,
      title: "Remodeling",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
    {
      src: completejob4,
      title: "Roofing",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
    {
      src: completejob5,
      title: "Roofing",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
    {
      src: completejob6,
      title: "Cleaning",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
    {
      src: completejob7,
      title: "Remodeling",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
    {
      src: completejob8,
      title: "Cleaning",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
    },
  ];
  return (
    <section className="main-completed--jobs-sec-h">
      <div className="main-completed--jobs-cont-h">
        <h1 className="completed--jobs--main--title">Completed Jobs</h1>
        <div className="slider-container">
          <Slider {...settings}>
            {jobsData.map((slide, index) => (
              <div className="slide slide--alt--h slide--alt--h--2" key={index}>
                <img src={slide.src} alt={`Slide ${index + 1}`} />
                <h1 className="completed--jobs--title--h">{slide.title}</h1>
                <p className="completed--jobs--text--h">{slide.text}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default CompletedJobs;
