const Contactpoph = () => {
  return (
    <div className="contact-us-popup-h">
      <div className="main-contact-pop-h">
        <div className="contact-popup-title-h">
          <h1>Contact Us</h1>
        </div>
        <div className="contact-us-details-cont-h">
          <div>
            <h2>For Any Queries Contact Us at:</h2>

            <div className="contact-us-detail-cont-h">
              <div className="contact-us-detail-cont-h-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_20_150)">
                    <path
                      d="M18.3811 24C17.046 23.8601 15.8778 23.5123 14.7417 23.0467C12.4215 22.0955 10.3624 20.7255 8.45581 19.1164C5.83222 16.902 3.61911 14.3401 1.93215 11.3433C1.04381 9.76524 0.370357 8.10452 0.0431244 6.31127C-0.0555399 5.77059 0.00536398 5.3098 0.327438 4.84965C1.41597 3.29485 2.60152 1.82551 4.00102 0.536608C4.15134 0.398149 4.31636 0.272777 4.48646 0.158992C4.82974 -0.0705101 5.06927 -0.0538463 5.38002 0.22393C5.51896 0.348157 5.65223 0.485472 5.75963 0.636948C6.85483 2.18153 7.86841 3.7766 8.68266 5.48917C9.10863 6.38515 9.06349 6.55586 8.32476 7.21633C7.80586 7.68027 7.28553 8.14278 6.76175 8.60135C6.6388 8.70899 6.61415 8.81355 6.6618 8.97475C7.3685 11.3639 8.71261 13.3354 10.6089 14.9326C11.8985 16.0188 13.3434 16.8363 14.9777 17.3012C15.1706 17.356 15.2928 17.3242 15.4189 17.1792C15.8347 16.7011 16.2619 16.2331 16.6778 15.755C17.4282 14.8925 17.5837 14.8465 18.6167 15.3452C20.2689 16.1429 21.8231 17.1085 23.3123 18.1753C24.1753 18.7935 24.2111 19.1486 23.5039 19.9198C22.1456 21.4012 20.6033 22.6695 18.916 23.7608C18.7282 23.8821 18.5036 23.9464 18.3811 24Z"
                      fill="black"
                    />
                    <path
                      d="M23.9938 11.1252C23.4246 11.1252 22.8739 11.1252 22.2956 11.1252C22.2373 8.55605 21.349 6.3196 19.5244 4.49224C17.6974 2.66252 15.4623 1.76425 12.8639 1.70439C12.8639 1.14369 12.8639 0.587351 12.8639 0.0309393C18.3494 -0.142492 23.9743 4.50698 23.9938 11.1252Z"
                      fill="black"
                    />
                    <path
                      d="M19.6897 11.1241C19.1195 11.1241 18.5688 11.1241 18.0183 11.1241C17.9738 8.33703 15.7667 6.05317 12.8621 5.98072C12.8621 5.42495 12.8621 4.86847 12.8621 4.31206C16.4024 4.1859 19.763 7.29622 19.6897 11.1241Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_20_150">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="contact-us-detail-cont-h-text">
                <h3>Phone Number</h3>
                <p>469-223-3255</p>
              </div>

              <div className="contact-us-detail-cont-h-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="20"
                  viewBox="0 0 28 20"
                  fill="none"
                >
                  <path
                    d="M28 17.8307C27.9224 18.0719 27.8447 18.3131 27.7727 18.5371C24.9435 15.6892 22.1323 12.8592 19.3154 10.0237C22.1483 7.1753 24.966 4.34216 27.7871 1.50562C27.8452 1.68673 27.9226 1.92793 28 2.16907C28 7.38957 28 12.6102 28 17.8307Z"
                    fill="black"
                  />
                  <path
                    d="M26.579 0.257662C26.1558 0.678199 25.7556 1.06772 25.3643 1.46615C21.8311 5.0629 18.2997 8.66136 14.7672 12.2589C14.2721 12.7632 13.732 12.7645 13.24 12.2633C9.37337 8.32474 5.50702 4.3858 1.64081 0.446711C1.58376 0.388616 1.52977 0.32748 1.41821 0.207358C1.74572 0.133534 2.02093 0.065423 2.29898 0.0120027C2.40484 -0.00832677 2.51705 0.00332184 2.62634 0.00332184C10.2077 0.00302506 17.7891 0.00347023 25.3705 0.00206053C25.7725 0.00198633 26.1625 0.0444259 26.579 0.257662Z"
                    fill="black"
                  />
                  <path
                    d="M18.0961 11.2395C20.9173 14.0775 23.7242 16.9011 26.5945 19.7886C26.2575 19.8653 25.9837 19.9346 25.7068 19.9871C25.592 20.0088 25.4706 19.9964 25.3522 19.9964C17.7814 19.9967 10.2107 19.9962 2.63996 19.9977C2.23581 19.9978 1.84314 19.9573 1.45813 19.7578C4.28467 16.916 7.09777 14.0878 9.91379 11.2566C10.6244 11.9747 11.3773 12.7396 12.1348 13.4998C12.9586 14.3266 14.1197 14.5471 15.1255 14.0376C15.4128 13.8921 15.6776 13.6767 15.9102 13.4487C16.5919 12.7807 17.252 12.09 17.9211 11.4087C17.9903 11.3384 18.063 11.2716 18.0961 11.2395Z"
                    fill="black"
                  />
                  <path
                    d="M0.238457 1.46948C3.05981 4.3093 5.8716 7.13939 8.68747 9.97371C5.85443 12.8238 3.03753 15.6576 0.222239 18.4898C0.0672865 18.1943 -0.000144314 17.813 0.00014791 17.4117C0.00212043 14.7933 0.0011707 12.1748 0.0011707 9.55637C0.0011707 7.22471 0.00241265 4.89314 1.79811e-06 2.56148C-0.000436539 2.16832 0.0792677 1.79935 0.238457 1.46948Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="contact-us-detail-cont-h-text">
                <h3>Email Address</h3>
                <p>honesthomehub@gmail.com</p>
              </div>

              <div className="contact-us-detail-cont-h-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="27"
                  viewBox="0 0 23 27"
                  fill="none"
                >
                  <path
                    d="M12.017 0C12.6336 0.115577 13.2628 0.185965 13.8643 0.355345C15.5611 0.833145 16.9562 1.79228 18.0661 3.16294C18.8897 4.18006 19.4521 5.33365 19.7272 6.61483C20.2076 8.85309 19.8878 10.9777 18.6667 12.9171C16.5569 16.268 14.4081 19.5941 12.2756 22.9306C12.2434 22.981 12.2121 23.0319 12.1783 23.0811C11.8117 23.6153 11.19 23.6185 10.8331 23.0765C10.4342 22.4708 10.05 21.8552 9.65977 21.2438C7.90122 18.4886 6.13428 15.7389 4.38744 12.9763C3.24076 11.1629 2.83814 9.1806 3.20088 7.06178C3.79816 3.57299 6.35294 0.919282 9.81186 0.174201C10.2007 0.0904433 10.6003 0.0568244 10.9948 0C11.3356 0 11.6763 0 12.017 0ZM15.3383 8.46876C15.3384 6.35064 13.6086 4.61621 11.4998 4.62C9.40008 4.62379 7.68147 6.34665 7.67366 8.45551C7.66585 10.5729 9.38984 12.313 11.4994 12.3171C13.6082 12.3211 15.3382 10.5869 15.3383 8.46876Z"
                    fill="black"
                  />
                  <path
                    d="M12.0735 26.2854C9.19081 26.2602 6.90097 26.0365 4.66458 25.4054C3.40563 25.0502 2.17887 24.604 1.16316 23.744C0.818785 23.4524 0.502254 23.0902 0.279179 22.6993C-0.212932 21.8368 -0.0383761 20.8464 0.671753 20.0494C1.38963 19.2438 2.31067 18.7491 3.2988 18.3802C3.95061 18.1369 4.62912 17.9664 5.29047 17.7474C5.46855 17.6884 5.54325 17.7446 5.63402 17.8875C6.85521 19.8114 8.08407 21.7304 9.30705 23.6531C9.77631 24.3908 10.4223 24.8428 11.3037 24.9061C12.2211 24.972 12.9906 24.651 13.5224 23.8957C14.0573 23.1359 14.5368 22.3367 15.0376 21.5529C15.8141 20.3375 16.5919 19.1227 17.3586 17.9011C17.4647 17.732 17.5584 17.7026 17.7461 17.7525C18.8916 18.057 20.0179 18.4088 21.0479 19.0151C21.6329 19.3594 22.1638 19.7676 22.5596 20.3323C23.2178 21.2713 23.128 22.4272 22.3534 23.2742C21.6921 23.9974 20.87 24.4665 19.9849 24.8383C18.3707 25.5164 16.6742 25.8742 14.9443 26.0637C13.8034 26.1886 12.6547 26.242 12.0735 26.2854Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="contact-us-detail-cont-h-text">
                <h3>Location</h3>
                <p>Location</p>
              </div>
            </div>
          </div>
          <div>
            <input type="text" placeholder="Your Name" />
            <input type="text" placeholder="Email Address" />
            <input type="text" placeholder="Phone Number" />{" "}
            <textarea name="" id="" placeholder="Your Message"></textarea>
            <a href="#">Submit Query</a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contactpoph;
