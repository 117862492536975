export const main_color = "#002758";
export const vendor_color = "#01BAF2";
export const user_color = "#01BAF2";
export const lable_color = "#666666";


export const error_color_palette = {
  bg_color: "#fdf6f6",
  border_color: "#de7575",
  text_color: "#c20707",
};
