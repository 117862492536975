const webTheme = {
  vendor: {
    text_color: "white",
    bg_color: "#B22234",
  },
  customer: {
    text_color: "white",
    bg_color: "#2EB222",
  },
};

export { webTheme };
