import React from "react";
import vendor from '../../../src/assets/Successful.png'


const Waiting =()=>{



    return(

        <div>
            <div className="ahti69">

<img  src={vendor}/>
</div>


{/* <div class="Successful">
        <div class="main-Successful">
            <img src={vendor} alt=""/>
            <h2>Payment Successful</h2>
            <p>Chat with vendor and discuss your project</p>
            <button>Chat now</button>
        </div>
    </div> */}



            </div>

    )


}


export default Waiting;