import React from "react";

const Expert_Box = ()=>{

return (


    <div>
          <section className='hello-card-section-box'>
  <div class="hello-card">
    <div class="hello-card-body">

     
      <h2> Pick an Expert</h2>


      <p>Are you tired of sifting through endless quotes and options for your home needs? Simplify your decision-making process with our expert service. For just $19.99, our specialist will handle everything for you, meticulously evaluating all available quotes to ensure you get the best deal and quality. This small investment can save you hundreds of dollars in potential future costs and avoid the hassle of dealing with subpar work. Trust our expert to make the right choice for you, and enjoy peace of mind knowing your home is in capable hands.lit. </p>
    </div>
    <div class="hello-card-btn-box">
      <button>Hire An Expert</button>
    </div>
  </div>
</section>


    </div>


)



}


export default Expert_Box