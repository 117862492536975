import React, { useState } from "react";

import pic1 from "../../../src/assets/307ce493-b254-4b2d-8ba4-d12c080d6651.jpg";

const Show_Vendor_Profile = ({
  show,
  HidePopup,
  gig_keyword,
  gig_discription,
  gig_image,
  gig_title,
  vendor_phone,
  vendor_email,
  vendor_address,
  vendor_name,
  vendor_profile_picture,
}) => {
  // const [show, setshow] = useState(true);

  // const HidePopup = () => {
  //   setshow(false);
  //   window.location.reload();
  // };

  return (
    <div>
      {show && (
        <div class="main-popup-div main--popup--div--alt--h">
          <div class="list-1-popup-div">
            <div class="tital-list-1 tital--list--1--alt--h">
              <div class="list-tital img">
                <img
                  src={vendor_profile_picture ? vendor_profile_picture : pic1}
                  alt=""
                />
              </div>

              <div class="list-tital-1">
                <div class="tital-list-para">
                  <span className="tital--list--para--name--h">
                    <h2>{vendor_name}</h2>
                  </span>
                  <p>Vendor</p>
                </div>

                <div class="tital-list-ides">
                  <span class="fukk-with">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.29297 8.33333C7.29297 7.61504 7.57831 6.92616 8.08622 6.41825C8.59413 5.91034 9.28301 5.625 10.0013 5.625C10.7196 5.625 11.4085 5.91034 11.9164 6.41825C12.4243 6.92616 12.7096 7.61504 12.7096 8.33333C12.7096 9.05163 12.4243 9.7405 11.9164 10.2484C11.4085 10.7563 10.7196 11.0417 10.0013 11.0417C9.28301 11.0417 8.59413 10.7563 8.08622 10.2484C7.57831 9.7405 7.29297 9.05163 7.29297 8.33333Z"
                        fill="#01BAF2"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.1456 7.3975C3.28384 5.72215 4.04697 4.16003 5.2835 3.02121C6.52003 1.8824 8.13956 1.25016 9.8206 1.25H10.1806C11.8616 1.25016 13.4812 1.8824 14.7177 3.02121C15.9542 4.16003 16.7174 5.72215 16.8556 7.3975C17.01 9.26791 16.4325 11.1253 15.2448 12.5783L11.2506 17.4633C11.0992 17.6486 10.9085 17.7979 10.6923 17.9005C10.4762 18.003 10.2399 18.0562 10.0006 18.0562C9.76133 18.0562 9.52505 18.003 9.30886 17.9005C9.09268 17.7979 8.90199 17.6486 8.7506 17.4633L4.75727 12.5783C3.56919 11.1254 2.9914 9.26802 3.1456 7.3975ZM10.0006 4.375C8.95079 4.375 7.94397 4.79204 7.20164 5.53437C6.45931 6.2767 6.04227 7.28352 6.04227 8.33333C6.04227 9.38315 6.45931 10.39 7.20164 11.1323C7.94397 11.8746 8.95079 12.2917 10.0006 12.2917C11.0504 12.2917 12.0572 11.8746 12.7996 11.1323C13.5419 10.39 13.9589 9.38315 13.9589 8.33333C13.9589 7.28352 13.5419 6.2767 12.7996 5.53437C12.0572 4.79204 11.0504 4.375 10.0006 4.375Z"
                        fill="#01BAF2"
                      />
                    </svg>{" "}
                    {vendor_address ? vendor_address : "Address"}
                  </span>

                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.3307 14.904 16.2256 14.887 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.3754 15.9304 8.06961 13.6246 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.69065 6.41806 8.49821 5.2128 8.5 4C8.5 3.45 8.05 3 7.5 3Z"
                        fill="#01BAF2"
                      />
                    </svg>
                    {vendor_phone ? vendor_phone : "PhoneNum"}
                  </span>

                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M20 8L12 13L4 8V18H20V8ZM20 6H4L12 10.99L20 6Z"
                        fill="#01BAF2"
                      />
                      <path
                        d="M4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20ZM20 6L12 10.99L4 6H20ZM4 8L12 13L20 8V18H4V8Z"
                        fill="#01BAF2"
                      />
                    </svg>
                    {vendor_email}
                  </span>
                </div>
              </div>

              <div class="your-tital">
                <h2>4.5</h2>
                <h4>Rating</h4>
              </div>
            </div>
          </div>

          <div class="list-2-popup-div">
            <h2>Intro</h2>
            <p>{gig_title ? gig_title : "Title"}</p>
          </div>

          <div class="list-3-popup-div">
            <div class="boxin-1">
              <h2>{gig_keyword ? gig_keyword : "Profession"}</h2>
              <p>{gig_discription ? gig_discription : "Description"}</p>

              <div class="div-spanir">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M27.3072 12.9979L22.3134 8.00417L19.303 10.9792L16.2926 8.00417L20.2947 3.96667C20.578 3.68333 20.8967 3.47697 21.2509 3.34758C21.6051 3.2182 21.9592 3.15303 22.3134 3.15208C22.6912 3.15208 23.0515 3.21725 23.3943 3.34758C23.7372 3.47792 24.038 3.68428 24.2967 3.96667L27.3072 6.97708C27.7322 7.37847 28.0452 7.84503 28.2464 8.37675C28.4476 8.90847 28.5477 9.44539 28.5467 9.9875C28.5467 10.5306 28.4466 11.0618 28.2464 11.5813C28.0462 12.1007 27.7331 12.5729 27.3072 12.9979ZM7.79258 19.5146C7.36758 19.0896 7.15508 18.5876 7.15508 18.0087C7.15508 17.4297 7.36758 16.9282 7.79258 16.5042L11.2634 12.9979L14.2738 16.0083L10.7676 19.5146C10.3662 19.9396 9.87602 20.1521 9.29708 20.1521C8.71813 20.1521 8.21663 19.9396 7.79258 19.5146ZM6.26966 30.0333C6.00994 29.75 5.80924 29.4374 5.66758 29.0955C5.52591 28.7536 5.45508 28.3933 5.45508 28.0146C5.45508 27.6368 5.52024 27.277 5.65058 26.9351C5.78091 26.5932 5.98727 26.2801 6.26966 25.9958L16.2926 16.0083L11.7947 11.475C11.3697 11.0736 11.1572 10.5839 11.1572 10.0059C11.1572 9.42792 11.3697 8.92595 11.7947 8.5C12.1961 8.075 12.6919 7.8625 13.2822 7.8625C13.8724 7.8625 14.3801 8.075 14.8051 8.5L19.303 12.9979L21.3217 10.9792L25.2884 15.0167C25.5717 15.3 25.7134 15.6306 25.7134 16.0083C25.7134 16.3861 25.5717 16.7167 25.2884 17C25.0051 17.2833 24.6745 17.425 24.2967 17.425C23.919 17.425 23.5884 17.2833 23.3051 17L10.2717 30.0333C9.98841 30.3167 9.67533 30.5235 9.33249 30.6538C8.98966 30.7842 8.64163 30.8489 8.28841 30.8479C7.93424 30.8479 7.58008 30.7771 7.22591 30.6354C6.87174 30.4937 6.553 30.2931 6.26966 30.0333Z"
                      fill="#B22234"
                    />
                  </svg>
                  {gig_keyword}
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M27.3072 12.9979L22.3134 8.00417L19.303 10.9792L16.2926 8.00417L20.2947 3.96667C20.578 3.68333 20.8967 3.47697 21.2509 3.34758C21.6051 3.2182 21.9592 3.15303 22.3134 3.15208C22.6912 3.15208 23.0515 3.21725 23.3943 3.34758C23.7372 3.47792 24.038 3.68428 24.2967 3.96667L27.3072 6.97708C27.7322 7.37847 28.0452 7.84503 28.2464 8.37675C28.4476 8.90847 28.5477 9.44539 28.5467 9.9875C28.5467 10.5306 28.4466 11.0618 28.2464 11.5813C28.0462 12.1007 27.7331 12.5729 27.3072 12.9979ZM7.79258 19.5146C7.36758 19.0896 7.15508 18.5876 7.15508 18.0087C7.15508 17.4297 7.36758 16.9282 7.79258 16.5042L11.2634 12.9979L14.2738 16.0083L10.7676 19.5146C10.3662 19.9396 9.87602 20.1521 9.29708 20.1521C8.71813 20.1521 8.21663 19.9396 7.79258 19.5146ZM6.26966 30.0333C6.00994 29.75 5.80924 29.4374 5.66758 29.0955C5.52591 28.7536 5.45508 28.3933 5.45508 28.0146C5.45508 27.6368 5.52024 27.277 5.65058 26.9351C5.78091 26.5932 5.98727 26.2801 6.26966 25.9958L16.2926 16.0083L11.7947 11.475C11.3697 11.0736 11.1572 10.5839 11.1572 10.0059C11.1572 9.42792 11.3697 8.92595 11.7947 8.5C12.1961 8.075 12.6919 7.8625 13.2822 7.8625C13.8724 7.8625 14.3801 8.075 14.8051 8.5L19.303 12.9979L21.3217 10.9792L25.2884 15.0167C25.5717 15.3 25.7134 15.6306 25.7134 16.0083C25.7134 16.3861 25.5717 16.7167 25.2884 17C25.0051 17.2833 24.6745 17.425 24.2967 17.425C23.919 17.425 23.5884 17.2833 23.3051 17L10.2717 30.0333C9.98841 30.3167 9.67533 30.5235 9.33249 30.6538C8.98966 30.7842 8.64163 30.8489 8.28841 30.8479C7.93424 30.8479 7.58008 30.7771 7.22591 30.6354C6.87174 30.4937 6.553 30.2931 6.26966 30.0333Z"
                      fill="#B22234"
                    />
                  </svg>
                  {gig_keyword}
                </span>
              </div>

              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 13V21M12 3V6M18 6H5C4.46957 6 3.96086 6.21071 3.58579 6.58579C3.21071 6.96086 3 7.46957 3 8V11C3 11.5304 3.21071 12.0391 3.58579 12.4142C3.96086 12.7893 4.46957 13 5 13H18L22 9.5L18 6Z"
                    stroke="#FB8500"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                3034 Incoming Leads
              </span>
            </div>

            <div class="boxin-2 boxin--2--alt--h">
              <img src={gig_image ? gig_image : "Image"} alt="" />
            </div>
          </div>

          <div class="list-4-popup-div" onClick={HidePopup}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M26.6654 26.6667L5.33203 5.33334M26.6654 5.33334L5.33203 26.6667"
                stroke="#002758"
                stroke-opacity="0.9"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <h4>Get Back To Main Screen</h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default Show_Vendor_Profile;
