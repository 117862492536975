import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const RegisterDropdown = () => {
  const navigate = useNavigate();

  function signUpToggle(signUpType) {
    navigate("mainSectiontwo", { state: { signUpType } });
  }
  return (
    <motion.ul
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="home--register--dropdown--h"
    >
      <li onClick={() => signUpToggle("Customer")}>Customer</li>
      <li onClick={() => signUpToggle("Vendor")}>Vendor</li>
      <li onClick={() => signUpToggle("Expert")}>Expert</li>
    </motion.ul>
  );
};

export default RegisterDropdown;
