import { Container, Grid, Stack, colors } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./hero.css";
import person_img from "../assets/dylannew.png";
import firstBannerImg from "../assets/girl-think-img.png";
import Homeimg from "../assets/home-dylan.png";
import reviewer from "../assets/reviewer.jpg";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import coupon from "../assets/coupon-img.png";
const HeroSection = () => {
  const navigation = useNavigate();

  const NavigateLogin = () => {
    navigation("/mainsection");
  };
  const NavigateSignUp = () => {
    navigation("/mainSectiontwo");
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [zipCode, setZipCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [options] = useState(["Option 1", "Option 2", "Option 3"]);

  const handleSearch = () => {
    console.log(
      `Searching for: Zip Code - ${zipCode}, Option - ${selectedOption}`
    );
    // Add your search logic here
  };
  return (
    //   <Stack className="honest-11111">
    //     <Container className="sec1 main-container honest-50">
    //       {/* <Stack sx={{ height: { md: "100vh", lg: '100vh', xl: "100vh", sm: '100vh', xs: '80vh' } }} ></Stack> */}
    //       <div className="padding_container honest-51">
    //         <Grid container zIndex={2} className="honest-52">
    //           <Grid
    //             item
    //             md={8}
    //             sm={12}
    //             lg={8}
    //             xs={12}
    //             xl={8}
    //             className="banner-contents honest-53"
    //           >
    //             <Stack className="banner-content honest-54">
    //               <Stack className="heading_landing_page honest-55">
    //                 {/* <h1 className='honest-56'>Home Services: They Way They Should Be</h1> */}
    //                 <h1 className="honest-56">
    //                   <h1>How Home Services</h1>
    //                   <h1>Should Be</h1>

    //                   {/* <span style={{color:"#B22234"}}>Home Services:</span>
    // <span style={{color:'white'}}> They Way They Should Be</span> */}
    //                 </h1>
    //                 <p className="landing-text-h">
    //                   Forget the old hassles. Experience top-notch home services
    //                   with ease.
    //                 </p>
    //                 <p className="landing-text-h">Right now. Right here.</p>

    //                 {/* <Link to='https://honesthomehub.com/mainsection' style={{textDecoration:'none'}} className='honest887'> */}
    //                 <div className="honest887">
    //                   <button
    //                     onClick={NavigateSignUp}
    //                     className="carousel_button honest-63"
    //                   >
    //                     Get Started Now
    //                   </button>
    //                 </div>
    //                 <br />

    //                 <div className="honest887">
    //                   <h3 className="honest-57" style={{ fontSize: "30px" }}>
    //                     The Honest Haley Promise: Guaranteed Satisfaction
    //                     <br />
    //                     or Your Money Back + A Free Cleaning!
    //                   </h3>
    //                 </div>

    //                 {/* <br />
    //                 <h3 className="honest-57">
    //                   No More Getting Ripped Off On Home Services
    //                 </h3>
    //                 <p className="hero_subtitle honest-58">
    //                   We connect you with three quotes and store them in an easily
    //                   manageable way.
    //                 </p>
    //                 <h3 className="honest-59">
    //                   Feel Comfortable During Your Home Service
    //                 </h3>
    //                 <p className="hero_subtitle honest-60">
    //                   Each vendor has a profile page with a selfie and
    //                   history/interests to make you feel more comfortable during
    //                   the home service.
    //                 </p>
    //                 <h3 className="honest-61">
    //                   Be Completely Hands Off With An Expert
    //                 </h3>
    //                 <p className="hero_subtitle honest-62">
    //                   Post a job and sit back with the help of an expert to decide
    //                   between quotes.
    //                 </p> */}
    //                 {/* </Link> */}
    //               </Stack>
    //             </Stack>
    //           </Grid>
    //           <Grid item md={4} sm={12} lg={4} xs={12} xl={4}>
    //             <Stack className="banner-image honest-64">
    //               <img src={person_img} className="honest8888888" />
    //             </Stack>
    //           </Grid>
    //         </Grid>
    //       </div>
    //     </Container>
    //   </Stack>

    <section className="home-first-banner home-first-banner-position-alt-h">
      <div className="main_container main-container-alt-h main-container-alt-width-h">
        <div className="first-banner-flex">
          <div className="first-banner-starthalf first-banner-starthalf-alt-h">
            {/* <div className="firstbanner-contact-detail">
              <div className="firstbanner-contact-detail-inner firstcontact-line">
                <h5>Email</h5>
                <h6>dummy@gmail.com</h6>
              </div>
              <div className="firstbanner-contact-detail-inner firstcontact-line">
                <h5>Phone</h5>
                <h6>+0000 222 333</h6>
              </div>
              <div className="firstbanner-contact-detail-inner firstcontact-line">
                <h5>Location</h5>
                <h6>Dummy-Dummy</h6>
              </div>
            </div> */}
            <div className="first-banner-img first-banner-img-alt-h">
              <img className="alt-img-home-h" src={Homeimg} alt="img" />
            </div>
          </div>
          <div className="first-banner-txt">
            <h2>
              How {/* <span onClick={toggle}> */}
              Home Services
              {/* {isOpen && (
                  <motion.ul
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -40 }}
                    transition={{ duration: 0.4 }}
                  >
                    <li>Option 1</li>
                    <li>Option 2</li>
                    <li>Option 3</li>
                  </motion.ul>
                )} */}
              {/* </span> */}
            </h2>
            <h5>Should Be</h5>
            <p>
              Forget the old hassles. <br /> Experience top-notch home services
              with ease. <br /> Right now. Right here.
            </p>
            {/* <button
                onClick={NavigateSignUp}
                className="theme_btn theme_btn-alt-1"
              >
                Get Started Now
              </button> */}
            <div className="home--search--zip--h">
              <select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="home--select--h"
              >
                <option style={{ display: "none" }} value="" disabled>
                  How can we help
                </option>
                {options.map((option, index) => (
                  <option style={{ color: "black" }} key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
                width={20}
                height={20}
                color="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                />
              </svg>

              <input
                type="text"
                placeholder="Zip Code"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="home--input--search--alt--h"
              />
              <button onClick={handleSearch} className="home--search--icon--h">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  width={25}
                  height={25}
                >
                  <path
                    color="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
              </button>
            </div>
            <button
              onClick={NavigateSignUp}
              className="theme_btn theme_btn-alt-2"
            >
              Download App
            </button>
            <div className="testimonial-review-h">
              <div className="dylan--coupon--cont--h">
                <img src={coupon} alt="coupon" />
              </div>
              <div className="testimonial-img-text-h">
                <div className="testimonial-img-cont-h">
                  <img src={reviewer} alt="" />
                </div>
                <h4>Larry</h4>
                <p>Dallas, Texas</p>
              </div>
              <h3>
                “Home projects were a hassle until I used Honest Home Hub.
                Described my project, got a qualified contractor with a lower
                bid, and the job was done in one day. Highly recommend!”
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-hero-text-h">
        The Honest Haley Promise: Guaranteed Satisfaction or Your Money Back + A
        Free Cleaning!
      </div>
    </section>
  );
};

export default HeroSection;
